import React from 'react'
import { graphql } from 'gatsby'

//STYLES
import '../styles/dynamic-pages-css/design-build-and-operate.css'
import '../styles/dynamic-pages-css/common-styles.css'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import DesignBuildAndOperateContent from '../components/designBuildAndOperateContent'
import InterestedBlock from '../components/interestedBlock'
import { useTranslation } from 'react-i18next'

const DesignBuildOperate = ({ data }) => {
  let md = data.markdownRemark.frontmatter
  const { t } = useTranslation()

  let designBuildAndOperateContent = md.sectionArray.map((item, i) => (
    <DesignBuildAndOperateContent
      key={i}
      icon={item.icon}
      blockTitle={t(`designBuildAndOperateBlockTitle.${i}`, {
        returnObjects: true,
      })}
      blockText={t(`designBuildAndOperateBlockText.${i}`, {
        returnObjects: true,
      })}
    />
  ))

  return (
    <div id="design-build-operate">
      <BackgroundImage
        className="header-img"
        fluid={md.headerImage.childImageSharp.fluid}
        alt="Index header image"
      >
        <Header />
        <div className="selling-text">
          <h1>{t('designBuildAndOperateTitle')}</h1>
        </div>
      </BackgroundImage>
      <main>
        <div className="first-grid">
          <div className="innovation-text">
            <p className="blue-text">
              {t('designBuildAndOperateTitle').toUpperCase()}
            </p>
            <h2 className="section-title">
              {t('designBuildAndOperateSubTitle')}
            </h2>
            <p className="section-ingress">
              {t('designBuildAndOperateMainIngress')}
            </p>
            <p className="section-text">{t('designBuildAndOperateMainText')}</p>
          </div>
          <div className="innovation-img">
            <Img
              className="working-image gatsby-image"
              fluid={md.mainImage.childImageSharp.fluid}
              alt="workingspace"
            />
          </div>
        </div>
        <div className="main-section">
          <div className="first-section">
            <p className="blue-text">
              {t('designBuildAndOperateSectionTitle').toUpperCase()}
            </p>
            <h2 className="section-title">
              {t('designBuildAndOperateSectionH1')}
            </h2>
            <p className="section-text">{md.sectionText}</p>
          </div>
          <div className="second-section">{designBuildAndOperateContent}</div>
          <p className="solutions">{md.solutions}</p>
        </div>
        <InterestedBlock
          interestedBlueText={md.interestedBlueText}
          interestedTitle={md.interestedTitle}
          interestedText={md.interestedText}
        />
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        blueText
        mainH1
        mainIngress
        mainText
        sectionBlueText
        sectionH1
        sectionText
        interestedTitle
        interestedText
        interestedBlueText
        solutions
        lang
        sectionArray {
          blockTitle
          blockText
          icon
        }
        mainImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default DesignBuildOperate
